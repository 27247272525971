import axios from '@/axios.js'

const state = () => ({
    kecamatans: []
})

const mutations = {
    SET_KECAMATANS (state, payload) {
      state.kecamatans = payload
    },
    ADD_KECAMATAN (state, item) {
      state.kecamatans.unshift(item)
    },
    UPDATE_KECAMATAN (state, kecamatan) {
      const kecamatanIndex = state.kecamatans.findIndex((p) => p.id === kecamatan.id)
      Object.assign(state.kecamatans[kecamatanIndex], kecamatan)
    },
    REMOVE_KECAMATAN (state, itemId) {
      const ItemIndex = state.kecamatans.findIndex((p) => p.id === itemId)
      state.kecamatans.splice(ItemIndex, 1)
    },
}

const actions = {
  fetchKecamatan ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get(`/term-list/kecamatan`)
        .then((response) => {
          commit('SET_KECAMATANS', response.data.data)
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },
  addKecamatan ({ commit }, kecamatan ) {
    return new Promise((resolve, reject) => {
      axios.post('/term-add', kecamatan)
        .then((response) => {
          commit('ADD_KECAMATAN', response.data.data)
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },
  updateKecamatan ({ commit }, kecamatan) {
    return new Promise((resolve, reject) => {
      axios.post(`/term-update/${kecamatan.id}`, kecamatan)
        .then((response) => {
            commit('UPDATE_KECAMATAN', response.data.data)
            resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },
  removeKecamatan ({ commit }, kecamatan) {
    return new Promise((resolve, reject) => {
      axios.delete(`/term-delete/${kecamatan.id}`)
        .then((response) => {
            commit('REMOVE_KECAMATAN', kecamatan.id)
            resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  }
}

export default {
  isRegistered: false,
  namespaced: true,
  state,
  actions,
  mutations
}
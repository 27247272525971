
<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary" class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
    <div class="mt-6 flex items-center justify-between px-6">
        <h4>{{ Object.entries(this.data).length === 0 ? "ADD NEW" : "UPDATE" }} KECAMATAN</h4>
        <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <component :is="scrollbarTag" class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">

      <div class="p-6">
        
        <vs-row class="mt-5">
          <vs-col vs-align="center" vs-w="8" class="pr-2">
            <vs-input label="Kecamatan" v-model="dataKecamatan" class="w-full" name="item-kecamatan" v-validate="'required'" />
            <span class="text-danger text-sm" v-show="errors.has('item-kecamatan')">{{ errors.first('item-kecamatan') }}</span>
          </vs-col>
          <vs-col vs-align="center" vs-w="4">
            <vs-input label="Kode" v-model="dataKodeKecamatan" class="w-full" name="item-kode-kecamatan" v-validate="'required'" />
            <span class="text-danger text-sm" v-show="errors.has('item-kode-kecamatan')">{{ errors.first('item-kode-kecamatan') }}</span>
          </vs-col>
        </vs-row>

        <vs-row class="mt-5">
          <vs-col vs-align="center" vs-w="6">
            <vs-select
              label="Group"
              v-model="dataGroup"
              >
                <vs-select-item value="0" text="Tidak ada"/>
                <vs-select-item :key="itemx.id" :value="itemx.id" :text="itemx.name" v-for="itemx in listKecamatan"/>
            </vs-select>
          </vs-col>

          <vs-col vs-align="center" vs-w="6">
            <vs-select
              label="Parent"
              v-model="dataParent"
              >
                <vs-select-item value="0" text="Tidak ada"/>
                <vs-select-item :key="itemx.id" :value="itemx.id" :text="itemx.name" v-for="itemx in listKecamatan"/>
            </vs-select>
          </vs-col>
        </vs-row>

      </div>
    </component>

    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid">Submit</vs-button>
      <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">Cancel</vs-button>
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { mapGetters } from 'vuex'

export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    VuePerfectScrollbar
  },
  data () {
    return {

      dataId: null,
      dataKecamatan: null,
      dataGroup: 0,
      dataParent: 0,
      dataKodeKecamatan: null,

      listKecamatan: [],

      settings: {
        maxScrollbarLength: 80,
        wheelSpeed: .80
      }
    }
  },
  watch: {
    isSidebarActive (val) {
      
      let configs = {
        headers: {
          'Authorization': 'Bearer ' + this.user.api_token,
        },
      }
      this.axios.get('/term-list/kecamatan', configs)
      .then((response) => {
        let {data} = response.data
        this.listKecamatan = data
      })
      .catch((error) => {
        let data = error.data
        this.listKecamatan = data
      })

      if (!val) return
      if (Object.entries(this.data).length === 0) {
        this.initValues()
        this.$validator.reset()
      } else {
        const { id, name, term_group, parent, kode  } = JSON.parse(JSON.stringify(this.data))
        this.dataId = id
        this.dataKecamatan = name
        this.dataGroup = term_group
        this.dataParent = parent
        this.dataKodeKecamatan = kode
        this.initValues()
      }
    }
  },
  computed: {
    isSidebarActiveLocal: {
      get () {
        return this.isSidebarActive
      },
      set (val) {
        if (!val) {
          this.$emit('closeSidebar')
          this.$validator.reset()
          this.initValues()
        }
      }
    },
    isFormValid () {
      return !this.errors.any() && this.dataKecamatan && this.dataKodeKecamatan
    },
    scrollbarTag () { return this.$store.getters.scrollbarTag },
    ...mapGetters({
      user : 'auth/user',
    }),
  },
  mounted(){
    
  },
  methods: {
    initValues () {
      if (this.data.id) return
      this.dataId = null
      this.dataKecamatan = null
      this.dataGroup = 0
      this.dataParent = 0
      this.dataKodeKecamatan = null
      this.dataPos = 0
      this.dataDK = 0
    },
    submitData () {
      this.$validator.validateAll().then(result => {
        if (result) {
          const obj = {
            id: this.dataId,
            name: this.dataKecamatan,
            term_group: this.dataGroup,
            parent: this.dataParent,

            meta : {
              kode: this.dataKodeKecamatan,
            },

            taxonomy: 'kecamatan'
          }

          if (this.dataId !== null && this.dataId >= 0) {
            this.$store.dispatch('kecamatan/updateKecamatan', obj)
          } else {
            delete obj.id
            this.$store.dispatch('kecamatan/addKecamatan', obj)
          }
          this.$emit('closeSidebar')
          this.initValues()
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 450px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
    // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
    height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

    &:not(.ps) {
      overflow-y: auto;
    }
}
</style>
